import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .widget-employment-tab {
    position: relative;
    padding-bottom: 80px;
    .title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      margin: 0 0 24px 0;
      padding: 5px 0 0 0;
      @media (max-width: 906px) {
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 16px 0;
      }
    }
    .table-overlay {
      align-items: center;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      background: radial-gradient(
        circle,
        rgba(241, 244, 247, 0.8) 0,
        rgba(249, 250, 252, 0.6) 20%
      );
      border-radius: 10px;
      bottom: 14px;
      display: flex;
      gap: 0;
      height: 159px;
      justify-content: center;
      position: absolute;
      width: 100%;
    }
  }
`;
