import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { ShimmerThumbnail } from "react-shimmer-effects";

import ResourceViewStyles from "./ResourceViewStyles";
import CardCustom from "../../../../components/CardCustom/CardCustom";
import WidgetOverlay from "../../WidgetOverlay/WidgetOverlay";
import { widgetResourceCategoriesAPI } from "../../../../services/widgetServices";

import EmptyResourcesImage from "../../../../assets/svg/error404.svg";
import PlaceholderCategoryImage from "../../../../assets/svg/placeholderCategoryImage.svg";

const TOTAL_RECORDS_COUNT = 12;
const VISIBLE_RECORDS_COUNT = 8;

const ResourceView = ({ token }) => {
  const [categories, setCategories] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const categoriesAPICall = async () => {
      try {
        setShowLoader(true);
        const response = await widgetResourceCategoriesAPI(token);
        if (response?.success && response?.data) {
          const { video_categories = [] } = response.data;
          setCategories(video_categories.slice(0, TOTAL_RECORDS_COUNT));
          setShowLoader(false);
        }
      } catch (error) {
        setCategories([]);
        setShowLoader(false);
      }
    };
    categoriesAPICall();
  }, [token]);

  const onCategoryClick = () => {
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <ResourceViewStyles>
      <div className="widget-resources-view">
        <div className="resource-categories-wrapper">
          {showOverlay && (
            <WidgetOverlay
              title="Sign up to access resources"
              closeOverlay={closeOverlay}
              route_type="resources"
            />
          )}
          {showLoader ? (
            <div className="resource-categories-loader">
              {[...Array(8)].map((_item, index) => (
                <ShimmerThumbnail
                  key={index}
                  width={255}
                  height={223}
                  rounded
                />
              ))}
            </div>
          ) : categories && !isEmpty(categories) ? (
            <>
              <div className="resource-categories-list">
                {categories.map((item, index) => (
                  <div className="resource-categories-card" key={index}>
                    {index >= VISIBLE_RECORDS_COUNT && (
                      <div className="overlay-card" />
                    )}
                    <CardCustom onClick={onCategoryClick}>
                      <div className="resource-categories-item">
                        <div className="resource-categories-image">
                          <img
                            src={item.logo_url || PlaceholderCategoryImage}
                            alt="category"
                          />
                        </div>
                        <span>{item.category_name}</span>
                      </div>
                    </CardCustom>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="resource-categories-empty">
              <img src={EmptyResourcesImage} alt="No categories" />
              <h3>We don't have any resource listed right now.</h3>
            </div>
          )}
        </div>
      </div>
    </ResourceViewStyles>
  );
};

export default ResourceView;
