import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

import PasswordTextboxStyles from "./PasswordTextboxStyles";
import TextboxCustom from "../Textbox/TextboxCustom";

// HOC for password type textbox to handle show/hide of password using eye icon
const PasswordTexbox = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <PasswordTextboxStyles>
      <TextboxCustom
        {...props}
        type={showPassword ? "text" : "password"}
        InputProps={{
          startAdornment: <div className="start-adornment"></div>,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={
                  showPassword ? "hide the password" : "display the password"
                }
                onClick={() => setShowPassword((currentState) => !currentState)}
                onMouseDown={(e) => e?.preventDefault && e.preventDefault()}
                onMouseUp={(e) => e?.preventDefault && e.preventDefault()}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOffOutlined htmlColor="#607790" />
                ) : (
                  <VisibilityOutlined htmlColor="#607790" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </PasswordTextboxStyles>
  );
};

export default PasswordTexbox;
