import styled from "styled-components";
import "../../../styles/colors.scss";

export default styled.div.attrs({ className: "" })`
  .widget-overlay {
    background-color: rgba(241, 244, 247, 0.9);
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 100;

    .dialog-view-body {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 54px 3px rgba(128, 148, 171, 0.4);
      margin: 30px auto;
      max-width: 669px;
      padding: 40px 10px 50px 10px;
      text-align: center;

      h2 {
        color: var(--primary-dark-color);
        font-family: TTCommons-DemiBold;
        font-size: 42px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 34px;
        margin: 0 0 30px;
        padding-top: 4px;
      }

      .small-title {
        color: var(--primary-dark-color);
        font-family: TTCommons-Medium;
        font-size: 30px;
        font-weight: 500;
        line-height: 25px;
        margin: 0 0 7px;
        padding-top: 5px;
        text-align: center;
      }

      .subtitle {
        color: var(--primary-dark-color);
        font-family: TTCommons-regular;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 30px;
        padding-top: 4px;
        text-align: center;
      }

      .login-text {
        color: var(--primary-dark-color);
        font-family: TTCommons-Medium;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        margin: 0 0 30px;
        padding-top: 4px;
        text-align: center;

        a {
          border-bottom: 1px solid var(--link-color);
          color: var(--link-color);
          margin-left: 4px;
          text-decoration: none;
        }
      }

      .close {
        display: flex;
        margin: -20px 20px 5px auto;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: flex-end;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
  }
`;
