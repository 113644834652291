import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useAppState } from "../../../context";
import { toastify } from "../../../helper/helper";
import { PRIMARY_COLOR } from "../../../styles/variables";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import PasswordTexbox from "../../PasswordTextbox/PasswordTextbox";

import { useUpdateUpdateIndividualProfile } from "../../../hooks/Profile/useUpdateUpdateIndividualProfile";

export default function PasswordUpdateDialog({ setUpdateEmailPopUp }) {
  const state = useAppState("profile");
  const { setLocalPersonalFormInfo } = state || {};
  const { updatePersonal } = useUpdateUpdateIndividualProfile();

  const validateFields = (values) => {
    let errors = {};
    setLocalPersonalFormInfo("password", values.password);
    if (!values.password?.trim()) {
      errors.password = "Password is required";
    } else if (values.password?.trim()?.length < 8) {
      errors.password = "Password should have at least 8 characters";
    } else if (!/(?=.*?[A-Z])(?=.*?[a-z])/.test(values.password)) {
      errors.password =
        "Password should have both upper and lowercase characters";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Both passwords should match";
    }
    return errors;
  };

  const successCallback = (actions) => {
    toastify("success", "Password has been successfully updated");
    actions.setSubmitting(false);
    setUpdateEmailPopUp(false);
  };
  const handleSubmit = async (_, actions) => {
    try {
      actions.setSubmitting(true);
      await updatePersonal(successCallback.bind(this, actions));
    } catch (err) {
      actions.setSubmitting(true);
      toastify(
        "error",
        err && err.data ? err.data.message : "Something went wrong"
      );
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirm_password: "",
      }}
      validate={validateFields}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, ...props }) => (
        <Form>
          <div className="change-passwords-form">
            <Field
              name="password"
              {...props}
              type="select"
              className="input-wrap"
            >
              {({ field, form: { errors }, meta }) => (
                <PasswordTexbox
                  {...field}
                  label="New password*"
                  name="password"
                  placeholder="Enter your new password"
                  lableaignment="left"
                  textcolor={PRIMARY_COLOR}
                  error={meta.touched && !isEmpty(errors["password"])}
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field
              name="confirm_password"
              {...props}
              type="select"
              className="input-wrap"
            >
              {({ field, form: { errors }, meta }) => (
                <PasswordTexbox
                  {...field}
                  label="Confirm password*"
                  name="confirm_password"
                  placeholder="Confirm password"
                  lableaignment="left"
                  textcolor={PRIMARY_COLOR}
                  error={meta.touched && !isEmpty(errors["confirm_password"])}
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>
            {/* <div className="checkbox-wrap">
              <p>
                Updating the email will sign you out from all active sessions
              </p>
              <p>
                You will receive a confirmation email for updated email address
              </p>
            </div> */}
            <div className="customized-button">
              <ButtonCustom
                isSubmitting={isSubmitting}
                isDisabled={!isEmpty(errors)}
                type="submit"
              >
                Apply Changes
              </ButtonCustom>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
