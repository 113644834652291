import React, { useState } from "react";

import EmploymentStyles from "./EmploymentStyles";
import {
  initialListingStateDataListing,
  initialStateTopEmployerGraph,
} from "../../JobPortal/EmploymentData/Utils/EmploymentDataConstants";
import TopEmployerGraph from "../../JobPortal/EmploymentData/Graphs/TopEmployerGraph/TopEmployerGraph";
import EmploymentDataTable from "../../JobPortal/EmploymentData/EmploymentDataTable/EmploymentDataTable";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";

const Employment = ({ token }) => {
  // Employer Graph state
  const [stateTopEmp, setStateTopEmp] = useState(initialStateTopEmployerGraph);
  // Data listing table state
  const [tableState, setTableState] = useState(initialListingStateDataListing);
  const [showOverlay, setShowOverlay] = useState(false);

  const processGraphClick = () => {
    const element = document.getElementById("overlay-graph");
    if (element) element.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const onButtonClick = () => {
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <EmploymentStyles>
      <div className="widget-employment-tab">
        <div className="employer-graph">
          <h2 className="title">Top employers for international alumni</h2>
          <TopEmployerGraph
            widget
            token={token}
            state={stateTopEmp}
            setState={setStateTopEmp}
            processGraphClick={processGraphClick}
          />
        </div>
        <div className="employer-data" id="overlay-graph">
          <h2 className="title">All international employment data</h2>
          <EmploymentDataTable
            widget
            tableState={tableState}
            setTableState={setTableState}
          />
          <div className="table-overlay">
            <ButtonCustom
              className={"bg-widget"}
              onClick={onButtonClick}
              aria-label={`Unlock full international employment data`}
            >
              Unlock full international employment data
            </ButtonCustom>
          </div>
        </div>
        {showOverlay && (
          <WidgetOverlay
            title="Access to full international employment data"
            closeOverlay={closeOverlay}
            route_type="employment_data"
          />
        )}
      </div>
    </EmploymentStyles>
  );
};

export default Employment;
