import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Tooltip } from "@mui/material";
import { Close, Notifications, FiberManualRecord } from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { isNull } from "lodash";

import HeaderStyles from "./HeaderStyles";
import { routes } from "../../../routes";
import {
  focusFirstElement,
  INTERSTRIDE_DEFAULT_LOGO,
  NoUserImage,
} from "../../../helper/helper";
import { Mixpanel } from "../../../mixpanel";
import { GRAY_COLOR } from "../../../styles/variables";
import NavLink from "../../atoms/NavLink/NavLink";
import CustomDialog from "../../CustomDialog/CustomDialog";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { useAppState } from "../../../context";
import { useFetchProfile } from "../../../hooks/Profile/useFetchProfile";
import MixpanelEvents from "../../../constant/MixpanelEvents.json";
import {
  signOutCall,
  STUDENT_VIEW_PREFERENCES_OPTIONS,
} from "../../../utils/common";
import { useChatsList } from "../../../hooks/useChatsList";
import { resetOPTInitialState } from "../../../redux/reducers/optReducer";

import ACTIVE_FAQ from "../../../assets/svg/FAQSelected.svg";
import NON_ACTIVE_FAQ from "../../../assets/svg/FAQ.svg";
import chatIcon from "../../../assets/svg/f-chat-icon.svg";
import chatIconActive from "../../../assets/svg/f-chat-icon-active.svg";

const checkIsActiveLink = (href, location) => {
  let headUrl = href.split("/");
  let locationSplit = location.split("/");
  if (headUrl[1] === locationSplit[1]) {
    return true;
  } else {
    return false;
  }
};

const Header = ({
  setActivePath,
  path,
  activeStep,
  notificationDialogOpen,
  setNotificationDialogOpen,
}) => {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showSignOutPopup, setShowSignOutPopup] = useState(false);
  const location = useLocation();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const { users = {}, permissions = {} } = useSelector(
    (store) => store.userStore
  );
  const {
    unread_notification_count,
    topics_notifcations_count,
    chat_notifcations_count,
    featured_job_notifications_count,
    employer_job_notifications_count,
  } = useSelector((store) => store.notificationStore);
  const { personalForm, resetProfileState, profile } = useAppState("profile");
  const { setShouldClearDiscoverFilter } = useAppState("network");
  const { resetToolsState } = useAppState("tools");
  const { reloadChats, resetChatStateOnLogout } = useAppState("chat");
  const { resetAmbassadorProfileState } = useAppState("ambassadorProfile");
  const { setShowIndexJobComponents } = useAppState("job");
  const dispatch = useDispatch();
  useChatsList(reloadChats);

  const handleShowProfile = () => {
    if (location.pathname === "/profile" && profile?.isProfileCompleted) {
      setShowProfile(false);
    } else {
      setShowProfile(!showProfile);
    }
  };
  const handleSignOut = async () => {
    if (users != null) {
      dispatch(resetOPTInitialState());
      resetProfileState();
      resetToolsState();
      resetChatStateOnLogout();
      resetAmbassadorProfileState();
      signOutCall(users.school_id);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePath(null); // 01-02-2021 - DM
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  // Custom Hooks call
  const state = useFetchProfile();

  const authData = users
    ? {
        // authentication_token: users.authentication_token,
        id: users.id,
        email: users.email,
        profile_image_url: users.profile_image_url,
        school_logo: users.school_logo,
        school_name: users.school_name,
        name: users.name,
        country_of_origin: users.country_of_origin,
        country_image: users.country_image,
        major: users.major,
        degree: users.degree,
        graduation_year: users.graduation_year,
      }
    : {};

  return (
    <HeaderStyles>
      <div className="header-wrapper">
        <div style={{ display: "none" }} id="extension-auth-data">
          {JSON.stringify(authData)}
        </div>
        <div className="header" onMouseLeave={() => setShowTooltip(null)}>
          <a
            className="skip-content-link"
            aria-label="skip to main content"
            href="/skip-to-main-content"
            onClick={(e) => {
              e.preventDefault();
              focusFirstElement("main-page-content");
            }}
          >
            skip to main content
          </a>
          <div className="logos">
            <Link to={routes.DASHBOARD} className="logo">
              <img src={INTERSTRIDE_DEFAULT_LOGO} alt="interstride logo" />
            </Link>
            <div className="university-logo">
              <img
                src={
                  users && users.school_logo ? users.school_logo : NoUserImage
                }
                alt={users ? users.school_name : "No School name"}
              />
            </div>
          </div>
          <nav
            className="navbar"
            role="navigation"
            aria-label={"Menu"}
            onMouseLeave={() => setShowTooltip(null)}
          >
            <ul className="menu">
              <li>
                <Tooltip
                  title="Search for U.S. and international opportunities"
                  placement="bottom"
                  open={!isNull(showTooltip) && showTooltip === "Jobs"}
                  disableHoverListener
                  disableFocusListener
                  disableTouchListener
                  onMouseEnter={() => setShowTooltip("Jobs")}
                  onMouseLeave={() => setShowTooltip(null)}
                  onFocus={() => setShowTooltip("Jobs")}
                  onBlur={() => setShowTooltip(null)}
                >
                  <div className="header-link-with-dot">
                    <NavLink
                      to={routes.JOBS.SEARCH}
                      onClick={() => {
                        setActivePath(null);
                        setShowIndexJobComponents(true);
                      }}
                    >
                      Jobs
                    </NavLink>
                    {(featured_job_notifications_count > 0 ||
                      employer_job_notifications_count > 0) && (
                      <FiberManualRecord />
                    )}
                  </div>
                </Tooltip>
              </li>
              {(permissions["chat"] || permissions["topics"]) && (
                <li>
                  <Tooltip
                    title="Network with international peers and alumni at your institution"
                    placement="bottom"
                    open={!isNull(showTooltip) && showTooltip === "Network"}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    onMouseEnter={() => setShowTooltip("Network")}
                    onMouseLeave={() => setShowTooltip(null)}
                    onFocus={() => setShowTooltip("Network")}
                    onBlur={() => setShowTooltip(null)}
                  >
                    <div className="header-link-with-dot">
                      <NavLink
                        to={
                          permissions["topics"]
                            ? routes.NETWORK.TOPICS
                            : permissions["chat"] && routes.NETWORK.DISCOVER
                        }
                        getProps={({ isPartiallyCurrent, href, location }) => {
                          let setActive = checkIsActiveLink(
                            href,
                            location.pathname
                          );
                          return {
                            className:
                              isPartiallyCurrent || setActive ? "active" : "",
                          };
                        }}
                        onClick={() => {
                          setActivePath(null);
                          setShouldClearDiscoverFilter(true);
                        }}
                      >
                        Network
                      </NavLink>
                      {topics_notifcations_count > 0 && <FiberManualRecord />}
                    </div>
                  </Tooltip>
                </li>
              )}
              {((permissions["webinar"] &&
                permissions["pastOrUpcomingWebinarsAvailable"]) ||
                (permissions["courses"] &&
                  users?.student_view_preference ===
                    STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL) ||
                permissions["resources"] ||
                (permissions["student_services"] &&
                  users?.student_view_preference ===
                    STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL)) && (
                <li>
                  <Tooltip
                    title="Resources to succeed during your international education"
                    placement="bottom"
                    open={!isNull(showTooltip) && showTooltip === "Learn"}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    onMouseEnter={() => setShowTooltip("Learn")}
                    onMouseLeave={() => setShowTooltip(null)}
                    onFocus={() => setShowTooltip("Learn")}
                    onBlur={() => setShowTooltip(null)}
                  >
                    <div>
                      <NavLink
                        to={
                          permissions["webinar"] &&
                          permissions["pastOrUpcomingWebinarsAvailable"]
                            ? routes.LEARN.WEBINARS
                            : permissions["courses"] &&
                              users?.student_view_preference ===
                                STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
                            ? routes.LEARN.COURSES
                            : permissions["resources"]
                            ? routes.LEARN.RESOURCES
                            : permissions["student_services"] &&
                              users?.student_view_preference ===
                                STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
                            ? routes.LEARN.STUDENT_SERVICES
                            : routes.DASHBOARD
                        }
                        getProps={({ isPartiallyCurrent, href, location }) => {
                          let setActive = checkIsActiveLink(
                            href,
                            location.pathname
                          );
                          return {
                            className:
                              isPartiallyCurrent || setActive ? "active" : "",
                          };
                        }}
                        onClick={() => {
                          setActivePath(null);
                        }}
                      >
                        Learn
                      </NavLink>
                    </div>
                  </Tooltip>
                </li>
              )}
              {(permissions["gameplan"] ||
                permissions["checklist"] ||
                permissions["opt_calculator"]) && (
                <li>
                  <Tooltip
                    title="Career assessment and productivity tools"
                    placement="bottom"
                    open={!isNull(showTooltip) && showTooltip === "Tools"}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    onMouseEnter={() => setShowTooltip("Tools")}
                    onMouseLeave={() => setShowTooltip(null)}
                    onFocus={() => setShowTooltip("Tools")}
                    onBlur={() => setShowTooltip(null)}
                  >
                    <div>
                      <NavLink
                        to={
                          permissions["gameplan"]
                            ? routes.TOOLS.CAREER
                            : permissions["checklist"]
                            ? routes.TOOLS.CHECKLIST
                            : routes.TOOLS.OPT_CALCULATOR
                        }
                        getProps={({ isPartiallyCurrent, href, location }) => {
                          let setActive = checkIsActiveLink(
                            href,
                            location.pathname
                          );
                          return {
                            className:
                              isPartiallyCurrent || setActive ? "active" : "",
                          };
                        }}
                        onClick={() => {
                          setActivePath(null);
                        }}
                      >
                        Tools
                      </NavLink>
                    </div>
                  </Tooltip>
                </li>
              )}
            </ul>
            <ul
              className={`header-rightsidebar ${
                accessibilityHelp === "true" ? "wcag-rightsidebar" : ""
              }`}
            >
              {permissions["chat"] && (
                <li
                  className={`cursor-pointer ${
                    path === routes.NETWORK.MESSENGEN ? "active" : ""
                  }`}
                  onClick={() => {
                    navigate(routes.NETWORK.MESSENGEN);
                  }}
                >
                  <a
                    href={routes.NETWORK.MESSENGEN}
                    onClick={(e) => e.preventDefault()}
                    aria-label={"Message"}
                  >
                    {chat_notifcations_count > 0 ? (
                      <Badge
                        badgeContent={chat_notifcations_count}
                        color="secondary"
                        className="notifi-boxs"
                        overlap="rectangular"
                      >
                        <Avatar
                          className={`${
                            path === routes.NETWORK.MESSENGEN && "active"
                          }`}
                          src={
                            path === routes.NETWORK.MESSENGEN
                              ? chatIconActive
                              : chatIcon
                          }
                          alt={"Message"}
                        />
                      </Badge>
                    ) : (
                      <Avatar
                        className={`${
                          path === routes.NETWORK.MESSENGEN && "active"
                        }`}
                        src={
                          path === routes.NETWORK.MESSENGEN
                            ? chatIconActive
                            : chatIcon
                        }
                        alt={"Message"}
                      />
                    )}
                  </a>
                </li>
              )}

              <li
                className={`cursor-pointer ${
                  path === routes.HELP.DASHBOARD ? "active" : ""
                }`}
                onClick={() => {
                  navigate(routes.HELP.DASHBOARD);
                }}
              >
                <a
                  href={routes.HELP.DASHBOARD}
                  onClick={(e) => e.preventDefault()}
                  aria-label={"Profile"}
                >
                  <Avatar
                    src={
                      path === routes.HELP.DASHBOARD
                        ? ACTIVE_FAQ
                        : NON_ACTIVE_FAQ
                    }
                    className={`${
                      path === routes.HELP.DASHBOARD && "active"
                    } student-help-center-avatar`}
                    alt={"Help Center"}
                  />
                </a>
              </li>

              <li
                className={`cursor-pointer header-notification-icon-adjust ${
                  path === routes.NOTIFICATION.DASHBOARD ? "active" : ""
                }`}
              >
                <a
                  href="/notifications"
                  aria-label={
                    unread_notification_count
                      ? `${unread_notification_count} unread notifications`
                      : "Notifications"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setShowProfile(false);
                    setNotificationDialogOpen(true);
                  }}
                  style={{
                    pointerEvents: notificationDialogOpen ? "none" : "all",
                  }}
                >
                  {unread_notification_count > 0 ? (
                    <Badge
                      badgeContent={unread_notification_count}
                      color="secondary"
                      className="notifi-boxs"
                      overlap="rectangular"
                    >
                      <Avatar
                        className={`${
                          path === routes.NOTIFICATION.DASHBOARD &&
                          "noti-active"
                        }`}
                        alt={`${unread_notification_count} unread notifications`}
                      >
                        <Notifications />
                      </Avatar>
                    </Badge>
                  ) : (
                    <Avatar
                      className={`${
                        path === routes.NOTIFICATION.DASHBOARD ||
                        path === routes.NOTIFICATION.SETTINGS
                          ? "noti-active"
                          : ""
                      }`}
                      alt="Notifications"
                    >
                      <Notifications />
                    </Avatar>
                  )}
                </a>
              </li>

              <li
                className={`cursor-pointer nprofile-icon ${
                  path !== routes.PROFILE.PATH ? "active" : ""
                }`}
                onClick={() => {
                  setNotificationDialogOpen(false);
                  if (state?.profile?.isProfileCompleted) {
                    setShowProfile(false);
                    navigate(routes.PROFILE.PATH);
                  } else {
                    handleShowProfile();
                  }
                }}
              >
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  aria-label={"Profile"}
                  className="profile-redirect-modal ml-auto"
                  style={{ position: "relative" }}
                >
                  {showProfile && activeStep !== 5 && activeStep !== 0 ? (
                    <div className="MuiAvatar-root header-close-icon">
                      <Close htmlColor={GRAY_COLOR} />
                    </div>
                  ) : (
                    <Avatar
                      src={
                        personalForm?.profile_image_url ||
                        profile?.user_profile_url ||
                        users?.user_profile_url
                      }
                      alt={users?.name || "User profile"}
                      className={`${path === routes.PROFILE.PATH && "active"} `}
                    />
                  )}

                  {showProfile && activeStep !== 5 && activeStep !== 0 ? (
                    <ul className="submenu">
                      <li
                        onClick={(e) => {
                          navigate(routes.PROFILE.PATH);
                          e.preventDefault();
                        }}
                      >
                        <a
                          className="link-focus"
                          href={routes.PROFILE.PATH}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          aria-label={"Profile"}
                        >
                          Profile
                        </a>
                      </li>

                      <li
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSignOutPopup(true);
                        }}
                      >
                        <a
                          className="link-focus"
                          href="/#"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          aria-label="Sign Out"
                        >
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  ) : null}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {showSignOutPopup && (
        <CustomDialog
          open={showSignOutPopup}
          title={"Are you sure you want to sign out?"}
          handleClose={() => setShowSignOutPopup(false)}
          className="sign-out-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="btn mt-30 mb-30">
              <ButtonCustom
                width="200"
                height={50}
                onClick={(e) => {
                  e.preventDefault();
                  setShowSignOutPopup(false);
                  handleSignOut();
                }}
                aria-label="Cancel"
              >
                Sign Out
              </ButtonCustom>
            </div>
            <a
              href="/#"
              className="ngray-link"
              onClick={(e) => {
                e.preventDefault();
                setShowSignOutPopup(false);
              }}
            >
              Cancel
            </a>
          </div>
        </CustomDialog>
      )}
    </HeaderStyles>
  );
};

export default Header;
