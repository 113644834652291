import React from "react";

function EmploymentGraph({ selected = false }) {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 8C16.625 7.24061 17.2406 6.625 18 6.625H20C20.7594 6.625 21.375 7.24061 21.375 8V15C21.375 15.2071 21.2071 15.375 21 15.375H17C16.7929 15.375 16.625 15.2071 16.625 15V8Z"
        stroke={selected ? "#FFFFFF" : "var(--primary-dark-color)"}
        stroke-width="1.25"
        stroke-linejoin="round"
      />
      <path
        d="M0.625 6C0.625 5.24061 1.24061 4.625 2 4.625H4.00011C4.7595 4.625 5.37511 5.24061 5.37511 6V15C5.37511 15.2071 5.20722 15.375 5.00011 15.375H1C0.792894 15.375 0.625 15.2071 0.625 15V6Z"
        stroke={selected ? "#FFFFFF" : "var(--primary-dark-color)"}
        stroke-width="1.25"
        stroke-linejoin="round"
      />
      <path
        d="M8.625 2C8.625 1.24061 9.24061 0.625 10 0.625H12.0001C12.7595 0.625 13.3751 1.24061 13.3751 2V15C13.3751 15.2071 13.2072 15.375 13.0001 15.375H9C8.79289 15.375 8.625 15.2071 8.625 15V2Z"
        stroke={selected ? "#FFFFFF" : "var(--primary-dark-color)"}
        stroke-width="1.25"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default EmploymentGraph;
