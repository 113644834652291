import React from "react";

import { widgetRedirection } from "../../../utils/common";
import WidgetOverlayStyles from "./WidgetOverlayStyles";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";

import CloseIcon from "../../../assets/svg/close.svg";

const WidgetOverlay = ({
  title = "",
  subtitle = false,
  closeOption = true,
  closeOverlay = () => {},
  route_type,
}) => {
  const handleLoginClick = () => {
    widgetRedirection(route_type);
  };

  return (
    <WidgetOverlayStyles>
      <div className="widget-overlay">
        <div className="dialog-view-body">
          {closeOption && (
            <span className="close">
              <img src={CloseIcon} alt="close" onClick={closeOverlay} />
            </span>
          )}
          <h2 className={`title ${subtitle ? "small-title" : ""}`}>{title}</h2>
          {subtitle && <div className="subtitle">{subtitle}</div>}
          <div className="login-text">
            Already have an account?{" "}
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                handleLoginClick();
              }}
            >
              Log In
            </a>
          </div>
          <ButtonCustom
            className={"bg-widget"}
            onClick={handleLoginClick}
            aria-label={`Get started`}
          >
            Get started
          </ButtonCustom>
        </div>
      </div>
    </WidgetOverlayStyles>
  );
};

export default WidgetOverlay;
