import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { routes } from "../../routes";
import usePromise from "../../hooks/usePromise/usePromise";
import { useAppState } from "../../context";

import CountryInsight from "./CountryInsight/CountryInsight";
import EmploymentData from "./EmploymentData/EmploymentData";
import SearchResult from "./Search/SearchResult";
import Alert from "./Alert/Alert";
import Visa from "./Visa/Visa";
import JobsDashboard from "./JobsDashboard/JobsDashboard";
import CompanyDetail from "./Visa/CompanyDetail/CompanyDetail";
import SavedSearches from "./Search/SavedSearches/SavedSearches";
import SavedJobs from "./Search/SavedJobs/SavedJobs";
import FeaturedJobs from "../../components/Jobs/FeaturedJobsList/FeaturedJobsList";
import EmployerJobs from "../../components/Jobs/EmployerJobsList/EmployerJobsList";
import JobDetails from "../../components/Jobs/EmployerJobsDetails/EmployerJobsDetails";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Container from "../../components/Container/Container";
import { getJobsCountriesAPI } from "../../services/JobServices";
import { config } from "../../constant/config";
import { useSavedJobs } from "../../hooks/useSavedJobs";
import { setJobsCountryList } from "../../redux/reducers/jobReducer";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../utils/common";
import { trackMixpanelEvent } from "../../helper/helper";

import searchJobsFocusIcon from "../../assets/FocusIcons/MenuIcons/Jobs/Search.svg";
import visaJobsFocusIcon from "../../assets/FocusIcons/MenuIcons/Jobs/Visa.svg";
import countryInsightIcon from "../../assets/svg/Country.svg";
import employmentDataIcon from "../../assets/svg/Employment.svg";
import countryFocusInsightIcon from "../../assets/FocusIcons/MenuIcons/Learn/Country.svg";
import visaJobsIcon from "../../assets/svg/visaJobsIcon.svg";
import searchJobsIcon from "../../assets/svg/searchJobsIcon.svg";

const JOBS_COUNTRY_LIST_VERSION = config().VERSIONS.JOBS_COUNTRY_LIST;

const Jobs = ({
  permissions = {},
  studentViewPreference = STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users = {} } = useSelector((store) => store.userStore);
  const { jobsCountryListVersion, jobsCountryList = [] } = useSelector(
    (store) => store.jobStore
  );
  const { setShowIndexJobComponents } = useAppState("job");

  const [callingGetJobsCountriesAPI, refreshGetJobsCountriesAPI] =
    usePromise(getJobsCountriesAPI);
  const [withSubHeader, setWithSubheader] = useState(true);

  // Fetch saved jobs on first load
  useSavedJobs({ fetchJobs: true });

  const tabs = [
    {
      title: "Find Jobs",
      toolTip: "Filter for domestic and international opportunities",
      avatar: searchJobsIcon,
      focusAvatar: searchJobsFocusIcon,
      navigateURL: routes.JOBS.SEARCH,
      isActive: location.pathname === routes.JOBS.SEARCH,
      navigateSideEffect: () => setShowIndexJobComponents(true),
    },
    ...(permissions["visa_insights"] &&
    studentViewPreference === STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
      ? [
          {
            title: "U.S. Visa Insights",
            toolTip:
              "U.S. employers' past sponsorship record of hiring international candidates",
            avatar: visaJobsIcon,
            focusAvatar: visaJobsFocusIcon,
            navigateURL: routes.JOBS.VISA,
            isActive: location.pathname === routes.JOBS.VISA,
          },
        ]
      : []),
    ...(permissions["country_insights"]
      ? [
          {
            title: "Country Insights",
            toolTip: "Guide to traveling and working internationally",
            avatar: countryInsightIcon,
            focusAvatar: countryFocusInsightIcon,
            navigateURL: routes.JOBS.COUNTRY_INSIGHTS,
            isActive: location.pathname === routes.JOBS.COUNTRY_INSIGHTS,
          },
        ]
      : []),
    // Show employment menu only if school has uploaded data
    ...(permissions["employment_data"] &&
    permissions["opt_upload"] &&
    studentViewPreference === STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL
      ? [
          {
            title: "Employment Data Hub",
            toolTip: `The data hub provides employment information of international graduates${
              users?.school_name && " from " + users.school_name
            }`,
            avatar: employmentDataIcon,
            focusAvatar: employmentDataIcon,
            navigateURL: routes.JOBS.EMPLOYMENT_DATA,
            isActive: location.pathname === routes.JOBS.EMPLOYMENT_DATA,
          },
        ]
      : []),
  ];

  const subHeaderList = [
    {
      title: "Company Profiles",
      isActive: location.pathname === routes.JOBS.COMPANY_DETAILS,
    },
    {
      title: "Saved Searches",
      isActive: location.pathname === routes.JOBS.SAVED_SEARCHES,
    },
    {
      title: "Saved Jobs",
      isActive: location.pathname === routes.JOBS.SAVED_JOBS,
    },
    {
      title: "Featured Jobs",
      isActive: location.pathname === routes.JOBS.FEATURED_JOBS,
    },
    {
      title: "Featured Jobs",
      isActive: location.pathname === routes.JOBS.EMPLOYER_JOBS,
    },
    {
      title: "",
      isActive: location.pathname === routes.JOBS.JOB_DETAILS,
    },
  ];

  useEffect(() => {
    trackMixpanelEvent("Jobs Dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(permissions)) return;

    // Job module route protection
    if (
      ((location.pathname.includes(routes.JOBS.VISA) ||
        location.pathname.includes(routes.JOBS.COMPANY_DETAILS)) &&
        (!permissions["visa_insights"] ||
          studentViewPreference !==
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL)) ||
      (location.pathname.includes(routes.JOBS.COUNTRY_INSIGHTS) &&
        !permissions["country_insights"]) ||
      (location.pathname.includes(routes.JOBS.EMPLOYMENT_DATA) &&
        (!permissions["employment_data"] ||
          studentViewPreference !==
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL))
    ) {
      setTimeout(() => {
        navigate(routes.DASHBOARD);
        return;
      }, [2000]);
    }

    if (
      jobsCountryList.length === 0 ||
      !jobsCountryListVersion ||
      jobsCountryListVersion !== JOBS_COUNTRY_LIST_VERSION
    ) {
      refreshGetJobsCountriesAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  useEffect(() => {
    if (
      callingGetJobsCountriesAPI.hasFetched() &&
      callingGetJobsCountriesAPI.hasErrors() === false &&
      callingGetJobsCountriesAPI.data() &&
      callingGetJobsCountriesAPI.data().data &&
      callingGetJobsCountriesAPI.data().data.countries
    ) {
      dispatch(
        setJobsCountryList({
          data: callingGetJobsCountriesAPI.data().data.countries,
          version: JOBS_COUNTRY_LIST_VERSION,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetJobsCountriesAPI.isFetching()]);

  useEffect(() => {
    if (location?.pathname?.indexOf("jobs/job-details") !== -1) {
      setWithSubheader(false);
      return;
    }
    setWithSubheader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    return () => {
      setShowIndexJobComponents(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      title="Jobs Dashboard"
      withSubHeader={withSubHeader}
      tabs={tabs}
      subHeaderList={subHeaderList}
      location={location}
      viewOnlyNavbar
    >
      <Container>
        <Routes>
          <Route path="/" element={<JobsDashboard />} />
          <Route path="/search" element={<SearchResult />} />
          <Route path="/job-alerts" element={<Alert />} />
          <Route path="/saved-searches" element={<SavedSearches />} />
          <Route path="/saved-jobs" element={<SavedJobs />} />
          <Route path="/featured-jobs" element={<FeaturedJobs />} />
          <Route path="/featured-jobs-portal" element={<EmployerJobs />} />
          <Route path="/job-details/:id" element={<JobDetails />} />
          {permissions["visa_insights"] &&
          studentViewPreference ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL ? (
            <>
              <Route path="/visa-insights" element={<Visa />} />
              <Route
                path="/visa-insights/company"
                element={<CompanyDetail />}
              />
            </>
          ) : null}
          {permissions["country_insights"] && (
            <Route path="/country-insights" element={<CountryInsight />} />
          )}
          {permissions["employment_data"] &&
          permissions["opt_upload"] &&
          studentViewPreference ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL ? (
            <Route path="/employment-data" element={<EmploymentData />} />
          ) : (
            <></>
          )}
        </Routes>
      </Container>
    </MainLayout>
  );
};

export default Jobs;
