import React, { useEffect, useRef } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import DropdownCustom from "../../../../../components/DropdownCustom/DropdownCustom";
import { createModuleStyleExtractor } from "../../../../../utils/css";
import styles from "./AlumniNationalityGraph.module.scss";
import Card from "../../../../../components/CardCustom/CardCustom";
import { getGraphData } from "../../../../../services/employmentDataService";
import MarkerWorldMap from "../../../../../components/atoms/MarkerWorldMap/MarkerWorldMap";
import { createFriendlyTitle } from "../../../../../helper/helper";

import NoDataImage from "../../../../../assets/images/chart_placeholder.png";
import FilterIcon from "../../../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function AlumniNationalityGraph({
  filters = {},
  state,
  setState,
  openFilter,
  toggleFilter,
  processGraphClick,
  dataSchool = null,
}) {
  // Calls API upon component and/or selected school change
  useEffect(() => {
    (async () => {
      const response = await getGraphData({
        dataSchool: dataSchool,
        graph_type: "nationality",
        degree: state?.selectedFilters?.degree?.value,
        major: state?.selectedFilters?.major?.value,
        year: state?.selectedFilters?.year?.value,
      });
      if (response?.success && response?.data?.length > 0) {
        // Process data
        let _data_set = {};
        for (let i = 0; i < response.data.length; i++) {
          const formattedName = createFriendlyTitle(
            response.data[i]?.nationality
          );
          _data_set[formattedName] = response.data[i]?.count;
        }
        setState((current) => ({
          ...current,
          loader: false,
          data_set: _data_set,
        }));
        return;
      }
      setState((current) => ({
        ...current,
        loader: false,
        data_set: null,
      }));
    })();
  }, [state?.selectedFilters, dataSchool]);

  // We are storing reference of state to use in callback of graph click
  // so that when ever the click call back is triggered on graph, it has the latest state
  const stateRef = useRef(state);
  // Update stateRef with latest state
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return (
    <div className={cx("m-alum-nat")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div
          className={cx("m-alum-nat__header")}
          id={"collapsible-section-header"}
        >
          <h2 className={cx("m-alum-nat__header__title")}>
            Nationality of alumni
          </h2>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
          <div
            className={cx("m-alum-nat__header__filters")}
            id="collapsible-section-search-filter"
          >
            <label>Filter</label>
            <DropdownCustom
              name="degree"
              placeholder="Degree"
              value={state?.selectedFilters?.degree}
              justifyContent="left"
              options={filters?.degree}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    degree: option,
                  },
                }))
              }
              isClearable
            />
            <DropdownCustom
              name="major"
              placeholder="Major"
              value={state?.selectedFilters?.major}
              justifyContent="left"
              options={filters?.major}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    major: option,
                  },
                }))
              }
              isClearable
            />
            <DropdownCustom
              name="year"
              placeholder="Year"
              value={state?.selectedFilters?.year}
              justifyContent="left"
              options={filters?.year}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    year: option,
                  },
                }))
              }
              isClearable
            />
          </div>
        </div>
      </div>
      <div className={cx("m-alum-nat__body")}>
        <Card className={cx("m-alum-nat__body__card")}>
          {state.loader ? (
            <div className={cx("m-alum-nat__body__card__loader")}>
              <ShimmerThumbnail rounded />
            </div>
          ) : !state.loader &&
            (state.data_set === null ||
              Object.keys(state.data_set)?.length === 0) ? (
            <div className={cx("m-alum-nat__body__card__no-data")}>
              <img src={NoDataImage} alt="No Data Available" />
              <h3>No data available</h3>
            </div>
          ) : !state.loader && Object.keys(state.data_set)?.length > 0 ? (
            <div className={cx("m-alum-nat__body__card__graph")}>
              <MarkerWorldMap
                height={450}
                data={state.data_set}
                valueLabel="Alumni"
                onClick={(nationality) =>
                  processGraphClick(
                    { label: nationality, value: nationality },
                    "AlumniNationality",
                    stateRef?.current // use Reference to view latest state
                  )
                }
              />
            </div>
          ) : (
            <></>
          )}
        </Card>
      </div>
    </div>
  );
}
