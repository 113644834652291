import React, { useState, useEffect } from "react";
import { isEmpty, isNull } from "lodash";
import moment from "moment";

import WebinarsStyles from "./WebinarsStyles";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../helper/helper";
import { widgetUpcomingWebinarsAPI } from "../../../services/widgetServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import CardCustom from "../../../components/CardCustom/CardCustom";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import ReadMoreReadLess from "../../../components/atoms/ReadMoreLess/ReadMoreLess";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";

import webinarIcon from "../../../assets/png/Others.png";
import RSS from "../../../assets/png/handshake_rss_logo.png";
import Image from "../../../assets/svg/discover_no_people.svg";
import userAvtar from "../../../assets/svg/f-user-avatar.svg";

const MAX_LINES_WEBINAR = 5;
const TOTAL_RECORDS_COUNT = 6;
const VISIBLE_RECORDS_COUNT = 3;

const Webinars = ({ token }) => {
  const [webinars, setWebinars] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [callGetUpcomingWebinarsAPI, refreshGetUpcomingWebinarsAPI] =
    usePromise(widgetUpcomingWebinarsAPI);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    refreshGetUpcomingWebinarsAPI(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (
      callGetUpcomingWebinarsAPI.hasFetched() &&
      callGetUpcomingWebinarsAPI.hasErrors() === false &&
      callGetUpcomingWebinarsAPI.data()?.data
    ) {
      const { upcoming_webinars = [] } = callGetUpcomingWebinarsAPI.data().data;
      setWebinars((upcoming_webinars ?? []).slice(0, TOTAL_RECORDS_COUNT));
      setShowLoader(false);
    } else if (
      callGetUpcomingWebinarsAPI.hasFetched() &&
      callGetUpcomingWebinarsAPI.hasErrors() === true
    ) {
      setWebinars([]);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetUpcomingWebinarsAPI.isFetching()]);

  const onRegisterClick = () => {
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  const renderWebinars = (data, i) => {
    return (
      <div className="webinars-card">
        {i >= VISIBLE_RECORDS_COUNT && <div className="overlay-card" />}
        <CardCustom className={"webinar-cards "}>
          <div className="item">
            <div className="img-wrapper">
              {data.webinar_image && data.webinar_image.length > 0 ? (
                <img src={data.webinar_image} alt="Webinar Display" />
              ) : (
                <img src={webinarIcon} alt="Webinar Icon" />
              )}
              {data.webinar_category_name && (
                <div className="webinarCategory">
                  {data.webinar_category_name}
                </div>
              )}
            </div>
            <div className="webinar-description">
              <div className="header-primary">{data.name}</div>

              <div className="description">
                {data.rss_feed_id && data.rss_feed_id != null ? (
                  <img
                    src={RSS}
                    className="custom-rss-icon"
                    alt="RSS webinar"
                  />
                ) : (
                  ""
                )}
                <ReadMoreReadLess
                  maxLines={MAX_LINES_WEBINAR}
                  moreComp={
                    <a
                      href="/#"
                      className="cursor-pointer nlink-secondary read-more-link"
                    >
                      Read more
                    </a>
                  }
                  lessComp={
                    <a href="/#" className="cursor-pointer nlink-secondary">
                      Read less
                    </a>
                  }
                >
                  {data.description}
                </ReadMoreReadLess>
              </div>
            </div>
            <div className="webinar-schedule-info">
              <div className="sub-header-primary">
                {moment
                  .unix(data?.epoch_time)
                  .local()
                  .format("ddd MMMM Do, YYYY")}
              </div>
              <div className="sub-header-primary">
                {`${moment
                  .unix(data?.epoch_time)
                  .local()
                  .format("hh:mm a")} - ${calculateLocalEndTimeFromEpoch(
                  data?.epoch_time,
                  data && data.duration && !isNull(data.duration)
                    ? data.duration.split(" ")[0]
                    : 0,
                  "minutes"
                )}  (${getLocalTzAbbr()})`}{" "}
              </div>
              {data.presenter && data.presenter.length > 0 && (
                <div className="text-grey">
                  <span>
                    <img src={userAvtar} alt="User avatar" />
                  </span>
                  {data.presenter}
                </div>
              )}

              {data && data.attachment && data.attachment !== "" ? (
                <a
                  href={data.attachment}
                  download
                  target="blank"
                  aria-label="Open attachment"
                  className="text-secondary link-focus"
                >
                  <div className="download-btn">
                    <span>attachment</span>
                  </div>
                </a>
              ) : (
                ""
              )}
              <div className="btn">
                <ButtonCustom
                  className={`up-btn bg-widget`}
                  onClick={onRegisterClick}
                  aria-label={`Register for ${data.name}`}
                  tooltipText="Use your university email to register for webinars."
                >
                  Register
                </ButtonCustom>
              </div>
            </div>
          </div>
        </CardCustom>
      </div>
    );
  };

  return (
    <WebinarsStyles>
      <div className="widget-webinars-tab">
        <h2 className="title">Join webinars to get ahead of the game</h2>
        <div className="webinar-list">
          {showLoader ? (
            <JobsListingEffect count={5} />
          ) : webinars && !isEmpty(webinars) ? (
            <>{webinars.map((data, i) => renderWebinars(data, i))}</>
          ) : (
            <EmptyListWithImage
              Image={Image}
              title="Looks like there are no webinars with this search."
              subTitle="Try expanding your search to discover other webinars!"
              image
            />
          )}
        </div>

        {showOverlay && (
          <WidgetOverlay
            title="Sign up to access webinars"
            closeOverlay={closeOverlay}
            route_type="webinars"
          />
        )}
      </div>
    </WebinarsStyles>
  );
};

export default Webinars;
