import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversationChannel: null,
  conversationReactionsChannel: null,
};

export const webSocketReducer = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setConversationChannel: (state, action) => {
      state.conversationChannel = action.payload;
    },
    setConversationReactionsChannel: (state, action) => {
      state.conversationReactionsChannel = action.payload;
    },
    resetInitialState: () => initialState,
  },
});

export const {
  setConversationChannel,
  setConversationReactionsChannel,
  resetInitialState,
} = webSocketReducer.actions;

export default webSocketReducer.reducer;
