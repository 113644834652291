import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import getStartedIcon from "../../assets/svg/getStartedIcon.svg";
import guideIcon from "../../assets/svg/guideIcon.svg";
import profileSettingIcon from "../../assets/svg/profileSettingIcon.svg";
import networkTopicsIcon from "../../assets/svg/networkTopicsIcon.svg";
import toolsResourceIcon from "../../assets/svg/toolsResourceIcon.svg";
import eyesIcon from "../../assets/svg/eyesIcon.svg";
import { routes } from "../../routes";
import { addAccessibilityClass, trackMixpanelEvent } from "../../helper/helper";
import HelpDashboard from "./HelpDashboard/HelpDashboard";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Container from "../../components/Container/Container";

const HelpCenter = () => {
  const location = useLocation();
  const tabs = [
    {
      title: "Getting Started",
      avatar: getStartedIcon,
      navigateURL: routes.HELP.START,
      isActive: location.pathname === routes.HELP.START,
    },
    {
      title: "Interstride Guide",
      avatar: guideIcon,
      navigateURL: routes.HELP.GUID,
      isActive: location.pathname === routes.HELP.GUID,
    },
    {
      title: "Profile & Settings",
      avatar: profileSettingIcon,
      navigateURL: routes.HELP.PROFILE,
      isActive: location.pathname === routes.HELP.PROFILE,
    },
    {
      title: "Network, Communities & Jobs",
      avatar: networkTopicsIcon,
      navigateURL: routes.HELP.NETWORK,
      isActive: location.pathname === routes.HELP.NETWORK,
    },
    {
      title: "Tools & Resources",
      avatar: toolsResourceIcon,
      navigateURL: routes.HELP.TOOLS,
      isActive: location.pathname === routes.HELP.TOOLS,
    },
    {
      title: "TroubleShutting",
      avatar: eyesIcon,
      navigateURL: routes.HELP.TROUBLESHOOT,
      isActive: location.pathname === routes.HELP.TROUBLESHOOT,
    },
  ];

  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__withHeadingLayout";
    addAccessibilityClass();
  });

  useEffect(() => {
    trackMixpanelEvent("Help Center");
  }, []);

  return (
    <MainLayout title="Help Center" withSubHeader={false} tabs={tabs}>
      <Container>
        <Routes>
          <Route path="/" element={<HelpDashboard />} />
        </Routes>
      </Container>
    </MainLayout>
  );
};

export default HelpCenter;
