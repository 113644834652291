import React, { useEffect, useState } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import HorizontalBarChart from "../../../../../components/atoms/HorizontalBarChart/HorizontalBarChart";
import DropdownCustom from "../../../../../components/DropdownCustom/DropdownCustom";
import { createModuleStyleExtractor } from "../../../../../utils/css";
import styles from "./AlumniMajorsGraph.module.scss";
import Card from "../../../../../components/CardCustom/CardCustom";
import { getGraphData } from "../../../../../services/employmentDataService";
import { seedGraphWrapperHeightMajors } from "../../Utils/EmploymentDataConstants";

import NoDataImage from "../../../../../assets/images/chart_placeholder.png";
import FilterIcon from "../../../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

export default function TopEmployerGraph({
  filters = {},
  state,
  setState,
  openFilter,
  toggleFilter,
  processGraphClick,
  dataSchool = null,
}) {
  // Calls API upon component mount
  useEffect(() => {
    (async () => {
      const response = await getGraphData({
        dataSchool: dataSchool,
        graph_type: "major",
        degree: state?.selectedFilters?.degree?.value,
        year: state?.selectedFilters?.year?.value,
        nationality: state?.selectedFilters?.nationality?.value,
        employment_type: state?.selectedFilters?.employment_type?.value,
      });

      const id = Math.floor(Math.random() * 100 + 1);
      if (response?.success && response?.data?.length > 0) {
        // Process only first 50 records
        const processData = (response?.data ?? []).slice(0, 50);
        // Process data
        let _labels = [];
        let _data = [];
        let _graphHeight = seedGraphWrapperHeightMajors;

        for (let i = 0; i < processData.length; i++) {
          _labels.push(processData[i].major);
          _data.push(processData[i].count);
          // Increase graph height per record dynamically if more than 10 records
          if (i > 9) _graphHeight += 45; // Pixels
        }

        setState((current) => ({
          ...current,
          loader: false,
          graphHeight: _graphHeight,
          data_set: {
            labels: _labels,
            data: _data,
            id,
          },
        }));
        return;
      }
      setState((current) => ({
        ...current,
        loader: false,
        graphHeight: seedGraphWrapperHeightMajors,
        data_set: {
          labels: null,
          data: null,
          id,
        },
      }));
    })();
  }, [state?.selectedFilters, dataSchool]);

  return (
    <div className={cx("m-alum-majors")}>
      <div
        className={`collapsible-section ${
          openFilter ? "collapsible-section--open" : ""
        }`}
      >
        <div
          className={cx("m-alum-majors__header")}
          id={"collapsible-section-header"}
        >
          <h2 className={cx("m-alum-majors__header__title")}>
            Majors of alumni
          </h2>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            id="collapsible-section-header-icon"
          >
            <FilterIcon fill={openFilter} />
          </a>
          <div
            className={cx("m-alum-majors__header__filters")}
            id="collapsible-section-search-filter"
          >
            <label>Filter</label>
            <DropdownCustom
              name="degree"
              placeholder="Degree"
              value={state?.selectedFilters?.degree}
              justifyContent="left"
              options={filters?.degree}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    degree: option,
                  },
                }))
              }
              isClearable
            />
            <DropdownCustom
              name="nationality"
              placeholder="Nationality"
              value={state?.selectedFilters?.nationality}
              justifyContent="left"
              options={filters?.nationality}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    nationality: option,
                  },
                }))
              }
              isClearable
            />
            <DropdownCustom
              name="year"
              placeholder="Year"
              value={state?.selectedFilters?.year}
              justifyContent="left"
              options={filters?.year}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    year: option,
                  },
                }))
              }
              isClearable
            />
            <DropdownCustom
              name="Employment Type"
              placeholder="Employment Type"
              value={state?.selectedFilters?.employment_type}
              justifyContent="left"
              options={filters?.employment_type}
              onChange={(option) =>
                setState((current) => ({
                  ...current,
                  selectedFilters: {
                    ...current.selectedFilters,
                    employment_type: option,
                  },
                }))
              }
              isClearable
            />
          </div>
        </div>
      </div>
      <div className={cx("m-alum-majors__body")}>
        <Card className={cx("m-alum-majors__body__card")}>
          {state.loader ? (
            <div className={cx("m-alum-majors__body__card__loader")}>
              <ShimmerThumbnail rounded />
            </div>
          ) : !state.loader &&
            (state.data_set.labels === null || state.data_set.data === null) ? (
            <div className={cx("m-alum-majors__body__card__no-data")}>
              <img src={NoDataImage} alt="No Data Available" />
              <h3>No data available</h3>
            </div>
          ) : !state.loader &&
            state.data_set.labels?.length > 0 &&
            state.data_set.data?.length > 0 ? (
            <div className={cx("m-alum-majors__body__card__graph")}>
              <div
                // Dynamically adjust graph height. Helps with scroll
                style={{ height: state?.graphHeight + "px" }}
              >
                <HorizontalBarChart
                  labels={state?.data_set?.labels}
                  data={state?.data_set?.data}
                  id={state?.data_set?.id}
                  enableCursorPointer
                  onClickHandler={(coordinates) =>
                    processGraphClick(coordinates, "AlumniMajors")
                  }
                  height={484}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Card>
      </div>
    </div>
  );
}
