import { useReducer } from "react";
import { findIndex, filter, get, find } from "lodash";

function reducer(state, action) {
  return { ...state, ...action };
}

const initialArgs = {
  recommandedNetworks: [],
  recommandedUserDetails: null,
  topicDetails: null,
  discoverNetworks: [],
  shouldClearDiscoverFilter: false,
  customeSuggetionUserList: [],
  dashboardTopics: {
    following_topics: [],
    recommended_topics: [],
  },
};

const useNetwork = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const setRecommendedNetworks = (data) => {
    setState({
      recommandedNetworks: data,
    });
  };

  const setNetworkDashboardTopics = (data) => {
    setState({
      dashboardTopics: {
        following_topics: get(data, "following_topics", []),
        recommended_topics: get(data, "recommended_topics", []),
      },
    });
  };

  const changeNetworkDashboardTopics = (data) => {
    const { topicId, topicAction } = data;
    if (topicAction === "follow") {
      let willBeFollow = find(state.dashboardTopics.recommended_topics, (o) => {
        return o.id === topicId;
      });
      let index = findIndex(state.dashboardTopics.recommended_topics, (o) => {
        return o.id === topicId;
      });
      if (willBeFollow.topic_type === "public") {
        setState({
          dashboardTopics: {
            following_topics: [
              ...state.dashboardTopics.following_topics,
              willBeFollow,
            ], // add here
            recommended_topics: filter(
              state.dashboardTopics.recommended_topics,
              (o) => {
                return o.id !== topicId;
              }
            ),
          },
        });
      } else {
        let changeData = state.dashboardTopics.recommended_topics;
        changeData[index].following_status = "Requested";
        setState({
          dashboardTopics: {
            following_topics: [...state.dashboardTopics.following_topics],
            recommended_topics: changeData,
          },
        });
      }
    } else {
      let willBeUnFollow = find(state.dashboardTopics.following_topics, (o) => {
        return o.id === topicId;
      });
      setState({
        dashboardTopics: {
          following_topics: filter(
            state.dashboardTopics.following_topics,
            (o) => {
              return o.id !== topicId;
            }
          ),
          recommended_topics: [
            ...state.dashboardTopics.recommended_topics,
            willBeUnFollow,
          ], // add here
        },
      });
    }
  };

  const setUserDetails = (data) => {
    setState({
      // recommandedUserDetails: data.user_info
      recommandedUserDetails: data,
    });
  };

  const setTopicDetails = (data) => {
    setState({
      topicDetails: data.topic_details[0],
    });
  };

  const setDiscoverNetworks = (data) => {
    // console.log("DATA 2===>", data);
    setState({
      discoverNetworks: data,
    });
  };

  const setShouldClearDiscoverFilter = (data) => {
    setState({
      shouldClearDiscoverFilter: data,
    });
  };

  return {
    setRecommendedNetworks,
    setNetworkDashboardTopics,
    changeNetworkDashboardTopics,
    setUserDetails,
    setTopicDetails,
    setDiscoverNetworks,
    setShouldClearDiscoverFilter,
    ...state,
  };
};

export default useNetwork;
