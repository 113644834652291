import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .MuiPaper-elevation1 {
    box-shadow: none;
    margin-bottom: 15px;
  }
  .Mui-expanded {
    margin-bottom: 0px;
  }
  .MuiAccordionSummary-root {
    padding: 0 0 0 0;
    min-height: initial;
  }
  .MuiAccordionSummary-content {
    align-items: center;
    gap: 5px;
    margin: 6px 0 0 0;
    .accordion-view__title {
      font-family: "TTCommons-Regular";
      font-size: 15px;
      line-height: 17px;
      color: var(--f-light-gray-color);
      margin-left: 8px;
      a {
        height: 19px;
      }
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 6px 0;
  }
  .accordion-view__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 5px;
  }
  .accordion-view__dropdown {
    width: 100%;
    margin-top: 8px;
    > div {
      > div {
        margin-bottom: 0px !important;
      }
    }
  }
  .MuiAccordionDetails-root {
    padding: 0px;
  }
  .MuiIconButton-root {
    padding: 0px 12px;
  }
  .checkbox-filter-list {
    margin-bottom: 15px;
    padding-left: 6px;
  }

  .radio-filter-list {
    margin-bottom: 15px;
    padding-left: 6px;
    .radio-filter-item {
      display: flex;
      margin-bottom: 6px;
      .radio-filter-item-label {
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
        color: var(--gray-color);
        margin: 6px 0 0 5px;
      }
      .radio-filter-item-checked {
        color: var(--primary-dark-color) !important;
      }
    }
  }

  .textbox-label {
    color: var(--field-label-color);
    font-size: 15px;
    margin: 0px !important;
    line-height: 14px;
    display: block;
    font-family: "TTCommons-Regular";
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    .link-focus {
      margin-left: 8px;
      display: flex;
      margin-top: -4px;
    }
    .note-icon {
      margin-left: 8px;
      display: flex;
      text-decoration: none;
    }
  }
`;
