import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Picker from "emoji-picker-react";
import { ClickAwayListener, Tooltip } from "@mui/material";

import { useAppState } from "../../../../context";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { CircularAvatar } from "../../../atoms/CircularAvatar/CircularAvatar";
import styles from "./SingleMessageItemV2.module.scss";
import { deliveryTime } from "../../../../helper/helper";

import redCheckedIcon from "../../../../assets/svg/red-check-mark.svg";
import emojiPlusIcon from "../../../../assets/svg/emoji-plus.svg";
import emojiPlusExpand from "../../../../assets/svg/emoji-expand.svg";

const cx = createModuleStyleExtractor(styles);

export const SingleMessageItemV2 = ({
  item = {},
  isRecent = false,
  conversation = {},
  reactionsEmojiContainer = false,
  setReactionsEmojiContainer = () => {},
  handleReaction = () => {},
}) => {
  const { currentSearchMessageItem } = useAppState("chat");

  const { users = {} } = useSelector((store) => store.userStore);

  const {
    message,
    sender_image,
    sender_name,
    send_at,
    shared_file,
    file_name,
    id,
    sender_id,
    conversation_type,
    reactions,
  } = item;
  const { id: userId, admission_user_type } = users || {};
  const isOwnMessageAmbassador = userId === sender_id && admission_user_type;
  const isOtherMessageAmbassador =
    conversation?.user_info?.user_type?.includes("Ambassador");

  const [isOwnMessage] = useState(sender_id === userId);
  const [showPickerTop, setShowPickerTop] = useState(false);

  const openEmojiContainer = () => {
    const element = document.getElementById("reaction-ui" + id);
    const windowWidth = window.innerWidth;
    let cutOffvalue = 500;
    windowWidth >= 767 ? (cutOffvalue = 500) : (cutOffvalue = 450);
    setShowPickerTop(
      parseInt(element.getBoundingClientRect().top) > cutOffvalue
    );
    setReactionsEmojiContainer({
      open: true,
      messageId: id,
      isReactionsState: true,
    });
  };

  const closeEmojiContainer = () => {
    // Close reactions container
    setReactionsEmojiContainer({
      open: false,
      messageId: null,
      isReactionsState: true,
    });
    // Reset picker position
    setShowPickerTop(false);
  };

  const onMouseEnterLeaveEmojiTrigger = (open = false) => {
    if (
      // If expanded reactions container is open, then do not trigger mouse enter and leave events
      (reactionsEmojiContainer.open &&
        reactionsEmojiContainer.messageId === id &&
        !reactionsEmojiContainer.isReactionsState) ||
      // If message already has reactions then do not trigger mouse enter and leave events
      reactions?.reactions_data?.length > 0
    )
      return;

    open ? openEmojiContainer() : closeEmojiContainer();
  };

  const handleEmojiIconClick = () => {
    reactionsEmojiContainer.open ? closeEmojiContainer() : openEmojiContainer();
  };

  const computeTooltipText = (usersList = [], maxUsersToDisplay = 10) => {
    let text = usersList
      ?.slice(0, maxUsersToDisplay)
      ?.map((user) => user?.name)
      .join(", ");
    if (usersList.length > maxUsersToDisplay) {
      text += `, and ${usersList.length - maxUsersToDisplay} ${
        usersList - maxUsersToDisplay > 1 ? "others" : "more"
      }`;
    }
    text += " reacted";
    return text;
  };

  // Scroll into view the message upon search
  useEffect(() => {
    if (currentSearchMessageItem?.id === id) {
      const messageElement = document.getElementById(id);
      messageElement.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [currentSearchMessageItem, id]);

  // Use case handle
  useEffect(() => {
    if (
      reactions?.reactions_data?.length === 1 &&
      reactionsEmojiContainer.open
    ) {
      closeEmojiContainer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactions?.reactions_data?.length]);

  const ReactionsUI = () => (
    <div
      className={cx(["reaction", isOwnMessage ? "reaction--own" : ""])}
      id={"reaction-ui" + id}
    >
      {reactions?.reactions_data?.length > 0 && (
        <img
          className={cx("reaction__icon")}
          src={emojiPlusIcon}
          alt="emoji-plus"
          onClick={handleEmojiIconClick}
        />
      )}
      {reactionsEmojiContainer.open &&
        reactionsEmojiContainer.messageId === id &&
        reactionsEmojiContainer.isReactionsState && (
          <ClickAwayListener
            onClickAway={() =>
              setReactionsEmojiContainer({
                open: false,
                messageId: null,
                isReactionsState: true,
              })
            }
          >
            <div
              className={`chat-reaction-picker ${
                isOwnMessage && "chat-reaction-picker--own"
              } ${
                showPickerTop && reactions?.reactions_data?.length > 0
                  ? "chat-reaction-picker--top"
                  : showPickerTop && reactions?.reactions_data?.length === 0
                  ? "chat-reaction-picker--top-hover"
                  : !showPickerTop && reactions?.reactions_data?.length > 0
                  ? "chat-reaction-picker--bottom"
                  : "chat-reaction-picker--bottom-hover"
              }`}
            >
              <Picker
                onEmojiClick={(e) => handleReaction(e, id)}
                skinTonesDisabled
                reactionsDefaultOpen={reactionsEmojiContainer.isReactionsState}
                width="100%"
              />
              {reactionsEmojiContainer?.isReactionsState && (
                <div
                  className="chat-reaction-picker__mask"
                  onClick={() =>
                    setReactionsEmojiContainer((current) => ({
                      ...current,
                      isReactionsState: false,
                    }))
                  }
                >
                  <img src={emojiPlusExpand} alt="+" />
                </div>
              )}
            </div>
          </ClickAwayListener>
        )}
      {reactions?.reactions_data?.map((item, index) => (
        <div className={cx("reaction__item")}>
          <Tooltip
            key={index}
            placement="top"
            title={
              <div className={cx("reaction__item__tooltip")}>
                <p className={cx("reaction__item__tooltip__emoji")}>
                  {item?.emoji}
                </p>
                <p className={cx("reaction__item__tooltip__text")}>
                  {computeTooltipText(item?.reacted_users)}
                </p>
              </div>
            }
          >
            <div
              className={cx([
                "reaction__item__emoji",
                item.reacted_users?.map((user) => user.id)?.includes(userId)
                  ? "reaction__item__emoji--own"
                  : "",
              ])}
              onClick={() => handleReaction(item, id)}
            >
              <span>
                {item?.emoji} {item?.count}
              </span>
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className={cx("message")}
      id={id}
      onMouseEnter={() => onMouseEnterLeaveEmojiTrigger(true)}
      onMouseLeave={() => onMouseEnterLeaveEmojiTrigger(false)}
    >
      {isOwnMessage ? ( // Own Message
        <div
          className={cx("message__own")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__own__content")}>
            {!isRecent && (
              <div className={cx("message__own__content__title")}>
                <span className={cx("message__own__content__title__you")}>
                  You
                </span>
                {isOwnMessageAmbassador && (
                  <div className={cx("message__own__content__title__amb-tag")}>
                    <img src={redCheckedIcon} alt="red-check" />
                    <span>Ambassador</span>
                  </div>
                )}
                <span className={cx("message__own__content__title__delivery")}>
                  at {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__own__content__item",
                conversation_type === "system_generated"
                  ? "message__own__content__item__system"
                  : "",
              ])}
            >
              <span className={cx("message__own__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__own__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && file_name && (
                <a
                  className={cx("message__own__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <span>{file_name}</span>
                </a>
              )}
            </div>
          </div>
          <div className={cx("message__own__info")}>
            <div
              className={cx("message__own__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
        </div>
      ) : (
        // other message
        <div
          className={cx("message__other")}
          style={{ marginTop: isRecent ? "-20px" : "0px" }}
        >
          <div className={cx("message__other__info")}>
            <div
              className={cx("message__other__info__image")}
              style={{ visibility: isRecent ? "hidden" : "visible" }}
            >
              <CircularAvatar
                src={sender_image}
                round
                size={31}
                name={sender_name}
              />
            </div>
          </div>
          <div className={cx("message__other__content")}>
            {!isRecent && (
              <div className={cx("message__other__content__title")}>
                <span className={cx("message__other__content__title__name")}>
                  {sender_name?.split(" ")[0]}
                </span>
                {isOtherMessageAmbassador && (
                  <div
                    className={cx("message__other__content__title__amb-tag")}
                  >
                    <img src={redCheckedIcon} alt="red-check" />
                    <span>Ambassador</span>
                  </div>
                )}
                <span
                  className={cx("message__other__content__title__delivery")}
                >
                  at {deliveryTime(send_at)}
                </span>
              </div>
            )}
            <div
              className={cx([
                "message__other__content__item",
                conversation_type === "system_generated"
                  ? "message__other__content__item__system"
                  : "",
              ])}
            >
              <span className={cx("message__other__content__item__message")}>
                {message}
              </span>
              {message === "" && shared_file && file_name && (
                <em
                  className={cx(
                    "message__other__content__item__no-message-attachment"
                  )}
                >
                  New attachment
                </em>
              )}
              {shared_file && file_name && (
                <a
                  className={cx("message__other__content__item__attachment")}
                  href={shared_file}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <span>{file_name}</span>
                </a>
              )}
            </div>
          </div>
        </div>
      )}
      <ReactionsUI />
    </div>
  );
};
