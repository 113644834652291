import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token, axios_api_config } from "../helper/helper";

export const getSavedSearchesAPI = () => {
  return axiosInstance
    .get(`jobs/saved_job_searches`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** For Job Notification */
export const getJobSearchNotificationsAPI = (id, data) => {
  return axiosInstance
    .post(
      `jobs/${id}/update_job_search_notification`,
      data,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getRecommendedJobSearchesAPI = () => {
  return axiosInstance
    .get(`jobs/frequent_searches`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getRecommendedJobsAPI = () => {
  return axiosInstance
    .get(`jobs/recommended_jobs`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const searchJobsAPI = ({ params = {}, cancelToken }) => {
  const body = {
    sort: "date",
    ...params,
  };
  return axiosInstance
    .post(`jobs/search`, body, axios_api_config(cancelToken))
    .then((res) => {
      return res.data;
    });
};

export const searchTopEmployerJobsAPI = ({
  params = {},
  cancelToken = null,
}) => {
  const {
    sort = "date",
    search = "",
    city = null,
    state = null,
    job_type = null,
    work_type = null,
    publish_date = null,
    degree = null,
    experience = null,
    company_name = null,
    page = 1,
    visa = "top_1000_employers",
    job_search_type = "approx",
  } = params;
  return axiosInstance
    .get(
      `jobs/top_employer_jobs?keyword=${search}&page=${page}${
        city !== null ? `&city=${city}` : ""
      }${state !== null ? `&state=${state}` : ""}${
        job_type !== null ? `&job_type=${job_type}` : ""
      }${work_type !== null ? `&work_type=${work_type}` : ""}${
        publish_date !== null ? `&publish_date=${publish_date}` : ""
      }${degree !== null ? `&degree=${degree}` : ""}${
        experience !== null ? `&experience=${experience}` : ""
      }${
        company_name !== null ? `&company_name=${company_name}` : ""
      }&sort=${sort}&visa=${visa}&job_search_type=${job_search_type}`,
      axios_api_config(cancelToken)
    )
    .then((res) => {
      return res.data;
    });
};

export const saveJobsSearchAPI = (params) => {
  const body = {
    title: "",
    keyword: "",
    country: null,
    city: null,
    state: null,
    job_type: null,
    publish_date: null,
    search_type: "international",
    notification_frequency: "no_notification",
    company: null,
    ...params,
  };
  return axiosInstance
    .post(`jobs/save_job_search`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getSavedJobsAPI = (sort = "date") => {
  return axiosInstance
    .get(`jobs/favorite_jobs?sort=${sort}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateFavouriteJobsAPI = ({
  like = "like",
  userId,
  job_id = null,
  featured_job_id = null,
  employer_job_id = null,
  job_title = null,
  url = null,
  company = null,
  city = null,
  state = null,
  country = null,
  date = null,
  ...props
}) => {
  let body = {
    like: like,
    user_id: userId,
    job_title: job_title,
    job_link: url,
    company_name: company,
    city: city,
    state: state,
    country: country,
    publish_date: date,
    logo: props?.logo?.url ?? props?.logo_url ?? null,
    permalink: props?.permalink,
  };
  if (employer_job_id) {
    body = {
      ...body,
      employer_job_id: employer_job_id,
    };
  } else if (featured_job_id) {
    body = {
      ...body,
      featured_job_id: featured_job_id,
    };
  } else {
    body = {
      ...body,
      job_id: job_id,
    };
  }
  return axiosInstance
    .post(`jobs/favorite_job`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getSavedJobSearchesAPI = () => {
  return axiosInstance
    .get(`jobs/saved_jobs`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getIndustryFilterForCompany = (params = {}) => {
  const { company_type = "h1b", year = 2024, search_field = "" } = params;
  return axiosInstance
    .get(
      `companies/industry_select?company_type=${company_type}&year=${year}&search_field=${search_field}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getCompanyFilterForCompany = (params = {}) => {
  const { company_type = "h1b", year = 2024, search_field = "" } = params;
  return axiosInstance
    .get(
      `companies/company_select?company_type=${company_type}&year=${year}&search_field=${search_field}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getStateFilterForCompany = (params = {}) => {
  const { company_type = "h1b", search_field = "" } = params;
  return axiosInstance
    .get(
      `companies/state_select?company_type=${company_type}&search_field=${search_field}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getCityFilterForCompany = (params = {}) => {
  const {
    company_type = "h1b",
    year = 2024,
    state_ids = [],
    search_field = "",
  } = params;
  return axiosInstance
    .get(
      `companies/city_select?company_type=${company_type}&year=${year}&state_ids=${state_ids}&search_field=${search_field}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getJobClassificationFilterForCompany = (params = {}) => {
  const { company_type = "h1b", year = 2024, search_field = "" } = params;
  return axiosInstance
    .get(
      `companies/job_title_select?company_type=${company_type}&year=${year}&search_field=${search_field}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getJobTitleFilterForCompany = (params = {}) => {
  const { year = 2024, search_field = "" } = params;
  return axiosInstance
    .get(
      `companies/soc_title_select?year=${year}&search_field=${search_field}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getCompaniesAPI = (requestBody = {}) => {
  const body = {
    year: 2024,
    limit: "10",
    offset: "0",
    id: "search",
    search: {
      type: "h1b",
      company_ids: [""],
      industry_ids: [],
      state_ids: [],
      city_ids: [],
      job_title_ids: [],
      graduation_year_ids: [],
      soc_title_ids: [],
    },
    ...requestBody,
  };
  return axiosInstance
    .post(`companies/search`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const exportCompaniesAPI = (requestBody = {}) => {
  const body = {
    year: 2024,
    limit: "10",
    offset: "0",
    id: "search",
    search: {
      type: "h1b",
      company_ids: [""],
      industry_ids: [],
      state_ids: [],
      city_ids: [],
      job_title_ids: [],
      graduation_year_ids: [],
      soc_title_ids: [],
    },
    ...requestBody,
  };
  return axiosInstance
    .post(`companies/export`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getCompanyDetails = (
  company_id,
  company_type = "h1b",
  year = 2024
) => {
  return axiosInstance
    .get(
      `companies/${company_id}?company_type=${company_type}&year=${year}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getCompanyPetitions = (params, appliedVisaFilters) => {
  const {
    job_classification,
    job_title,
    visa,
    industry,
    company,
    city,
    state,
    graduationYear,
    presetFilter,
  } = appliedVisaFilters ?? {};
  const body = {
    ...params,
    search: {
      type: visa ?? params?.tab_type ?? "h1b",
      // Pass company id from selected company if set
      company_ids: params.company_id
        ? [params.company_id]
        : company
        ? [company.value]
        : [],
      industry_ids: industry ? [industry] : [],
      state_ids: state ? [state.value] : [],
      city_ids: city ? [city.value] : [],
      job_title_ids: job_classification ? [job_classification.value] : [],
      soc_title_ids: job_title ? [job_title.value] : [],
      graduation_year_ids: graduationYear ? [graduationYear] : [],
    },
    // Pass saved present filters if any
    ...(presetFilter ? { [presetFilter]: true } : {}),
  };
  return axiosInstance
    .post(`companies/sponsored_petitions`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const deleteSavedSearchAPI = (job_search_id) => {
  return axiosInstance
    .delete(
      `jobs/destroy_saved_search?job_search_id=${job_search_id}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getSavedCompaniesAPI = () => {
  return axiosInstance
    .get(`companies/favorite_companies`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateFavouriteCompanyAPI = (params) => {
  const body = {
    tab_type: "h1b",
    like: "like",
    ...params,
  };

  return axiosInstance
    .post(`companies/favorite`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateJobsSearchAPI = ({ id, ...params }) => {
  const body = {
    keyword: "",
    country: null,
    city: null,
    state: null,
    job_type: null,
    publish_date: null,
    search_type: "international",
    notification_frequency: "bi-weekly",
    ...params,
  };
  return axiosInstance
    .post(`jobs/${id}/update_job_search`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getMapDataAPI = (requestBody = {}) => {
  const body = {
    year: [2024],
    id: "search",
    search: {
      type: "h1b",
      company_ids: [""],
      industry_ids: [],
      state_ids: [],
      city_ids: [],
      job_title_ids: [],
      graduation_year_ids: [],
    },
    ...requestBody,
  };
  return axiosInstance
    .post(`companies/fetch_map_data`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** @Dipesh */
export const getJobsCountriesAPI = () => {
  return axiosInstance
    .get(`jobs/location_options`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

/** NOTE :: We are skipping pagination for this endpoint as of now discussed with Team on product call 22.03.2022 - TBS */
export const getFeaturedJobsAPI = (params = {}) => {
  const {
    search = null,
    city = null,
    state = null,
    job_type = null,
    work_type,
    publish_date = null,
    company = null,
    country = null,
    sort = "date",
    read = null,
    // page = 1,
    // per_page = 500, // Setting large page count to skip paging confirmed with Haseeb - TBS
  } = params;

  // This endpoint returns all data if page & per_page parameters are not passed - TBS
  return axiosInstance
    .get(
      `featured_jobs?sort=${sort}${read !== null ? `&read=${read}` : ""}${
        search !== null ? `&keyword=${search}` : ""
      }${city !== null ? `&city=${city}` : ""}${
        state !== null ? `&state=${state}` : ""
      }${country !== null ? `&country=${country}` : ""}${
        job_type !== null ? `&position=${job_type}` : ""
      }${work_type !== null ? `&work_type=${work_type}` : ""}${
        publish_date !== null ? `&publish_date=${publish_date}` : ""
      }${company !== null ? `&company=${company}` : ""}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getJobsCompanyDetailsAPI = (company_id, company_name) => {
  return axiosInstance
    .get(
      `company_details?${
        company_id ? `company_id=${company_id}` : `company_name=${company_name}`
      }`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};
