import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .widget-course-tab {
    position: relative;
    .title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      margin: 0 0 24px 0;
      padding: 5px 0 0 0;
      @media (max-width: 906px) {
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 16px 0;
      }
    }
    .courses-list-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
      justify-content: center;
      .course-card {
        padding: 0 5px;
        width: 33.33%;
        margin: 0 0 10px 0;
        @media (max-width: 906px) {
          width: 50%;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
        > a {
          text-decoration: none;
          box-shadow: var(--card-box-shadow);
          display: block;
          border-radius: 10px;
          padding: 12px;
          background: #fff;
          color: var(--primary-dark-color);
          font-size: 15px;
          font-family: "TTCommons-Regular";
          line-height: 17px;
          .image-wrapper {
            border-radius: 10px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              vertical-align: middle;
              object-position: center;
            }
          }
          .course-title {
            font-size: 18px;
            font-weight: 600;
            margin: 8px 0 12px;
            line-height: 20px;
            font-family: "TTCommons-DemiBold";
          }
          .course-key-points {
            margin: 12px 0;
            padding: 0 0 0 18px;
          }
          .course-tag {
            padding: 0px 15px 0;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 30px;
            background: #d5e5ff;
            height: 29px;
            font-weight: 600;
            width: fit-content;
            line-height: 30px;
            font-family: "TTCommons-DemiBold";
            margin: 16px 0 30px;
          }
          .course-link {
            a {
              display: flex;
              align-items: center;
              white-space: nowrap;
              color: var(--link-color);
              text-decoration: none;
              font-size: 15px;
              font-family: "TTCommons-DemiBold";
              font-weight: 600;
              img {
                max-width: 48px;
                margin: 0 0 0 10px;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .widget-overlay {
      }
    }
  }
`;
