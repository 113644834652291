import React, { useState, useEffect, useLayoutEffect } from "react";
import { Navigate } from "react-router-dom";
import cls from "classnames";
import { Tooltip } from "@mui/material";
import { each, without } from "underscore";
import { isEmpty, isNull, isUndefined, findIndex, get } from "lodash";

import NavLink from "../../atoms/NavLink/NavLink";
import SubHeaderStyles from "./SubHeaderStyles";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import {
  addAccessibilityClass,
  camelCase,
  trackMixpanelEvent,
} from "../../../helper/helper";
import { useAppState } from "../../../context";

const SubHeader = ({
  tabs = [],
  subHeaderList = [],
  classes,
  pageTitle,
  setActivePath,
  activePath,
  viewOnlyNavbar = false,
  location,
}) => {
  const [title, setTitle] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [redirectlink, setredirectlink] = useState("");

  const [breadcrumData, setbreadcrumData] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const { setShouldClearDiscoverFilter } = useAppState("network");
  useLayoutEffect(() => {
    document.body.className = "interstride-app-body__baseSubnavLayout";
    addAccessibilityClass();
  }, []);
  useEffect(() => {
    let title = pageTitle;
    if (
      subHeaderList.length > 0 &&
      findIndex(subHeaderList, ["isActive", true]) >= 0
    ) {
      let subHeaderIndex = findIndex(subHeaderList, ["isActive", true]);
      if (subHeaderIndex >= 0) title = subHeaderList[subHeaderIndex].title;
    } else {
      let index = findIndex(tabs, ["isActive", true]);
      if (index >= 0) {
        title = tabs[index].title;
      }
    }
    setTitle(title);

    return () => {};
  }, [tabs]);

  useEffect(() => {
    document.title = title !== "Interstride" ? title + " | Interstride" : title;
    window.scrollTo(0, 0); //To solve the issue of scrolling while navigating - TBS
  }, [title]);

  useEffect(() => {
    if (window.location.pathname) {
      let existData = window.location.pathname.split("/");
      if (existData && existData.length > 2) {
        setbreadcrumData(without(existData, ""));
      }
    }
  }, [window.location.pathname]);

  const redirectFn = (r_link) => {
    let linkData = "/";
    each(breadcrumData, function (b_data, index) {
      if (index <= r_link) {
        linkData = linkData + b_data + (index <= r_link - 1 ? "/" : "");
      }
    });
    setredirectlink(linkData);
    setRedirect(true);
  };

  return (
    <SubHeaderStyles>
      {redirect && <Navigate to={"/" + redirectlink} />}
      <HeaderContainer>
        <nav
          className="sub-menu"
          role="navigation"
          aria-label={"Sub-menu"}
          onMouseLeave={() => setShowTooltip(null)}
        >
          <ul>
            {tabs.map((item, i) => {
              let isDisplay = get(item, "isDisplay", true);
              return (
                isDisplay && (
                  <li
                    className={cls("", {
                      active: activePath === item.navigateURL,
                    })}
                    key={i}
                  >
                    <Tooltip
                      title={isEmpty(item.toolTip) ? "" : item.toolTip}
                      placement="bottom"
                      open={!isNull(showTooltip) && showTooltip === i}
                      disableHoverListener
                      disableFocusListener
                      disableTouchListener
                      onMouseEnter={() => setShowTooltip(i)}
                      onMouseLeave={() => setShowTooltip(null)}
                      onFocus={() => setShowTooltip(i)}
                      onBlur={() => setShowTooltip(null)}
                    >
                      <div
                        style={{
                          display:
                            item?.count > 0 && item?.hasNotificationBadge
                              ? "flex"
                              : "",
                          alignItems: "center",
                        }}
                      >
                        <NavLink
                          to={item.navigateURL}
                          onBlur={() => {
                            let img = document.getElementById(`submenu-${i}`);
                            if (img && item.focusAvatar) img.src = item.avatar;
                          }}
                          setActivePath={setActivePath}
                          onClick={() => {
                            setActivePath(null);
                            if (item?.navigateSideEffect) {
                              item.navigateSideEffect();
                            }
                            if (
                              !isUndefined(item.fullURL) &&
                              location.pathname === item.navigateURL
                            ) {
                              window.location.replace(item.fullURL);
                            }
                            if (title === "Discover Network") {
                              setShouldClearDiscoverFilter(true);
                            }
                          }}
                        >
                          <img
                            src={item.avatar}
                            className={item.className ? item.className : ""}
                            alt="Avatar"
                            id={`submenu-${i}`}
                          />
                          <span>{item.title}</span>
                        </NavLink>
                        {item.hasNotificationBadge && item.count > 0 && (
                          <span className="sub-menu-notification-badge">
                            {item.count} &nbsp;new
                          </span>
                        )}
                      </div>
                    </Tooltip>
                  </li>
                )
              );
            })}
          </ul>
        </nav>

        <div
          className="relative-div"
          style={{ display: viewOnlyNavbar ? "none" : "" }}
        >
          {breadcrumData && breadcrumData.length >= 2 && (
            <div>
              <div className="breadcrumb-div">
                <nav
                  role="navigation"
                  aria-label="breadcrumb"
                  className="card-breadcrumb"
                >
                  <ul>
                    {breadcrumData.map((b_data, index) => (
                      <React.Fragment key={index}>
                        {index === breadcrumData.length - 1 ? (
                          <li>
                            <span aria-current="page">
                              {camelCase(b_data)}
                              <span className="sr-only">Current Page</span>
                            </span>
                          </li>
                        ) : (
                          <li>
                            <a
                              aria-label={`Back to ${camelCase(b_data)}`}
                              onClick={(e) => {
                                e.preventDefault();
                                redirectFn(index);
                              }}
                              href="/#"
                              className="link-focus"
                            >
                              {camelCase(b_data)}
                            </a>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          )}
          <h1 className={`${classes.pageTitle} h1-maintitle`}>{title}</h1>
        </div>
      </HeaderContainer>
    </SubHeaderStyles>
  );
};

export default SubHeader;
