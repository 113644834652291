import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getGraphData = async ({
  dataSchool = null,
  graph_type = null,
  major = null,
  year = null,
  nationality = null,
  degree = null,
  employment_type = null,
  country = null,
}) => {
  const ApiUrl =
    dataSchool === "all"
      ? `opts/v2_graph_data?all_schools=true`
      : `opts/v2_graph_data?school_id=${dataSchool}`;
  const response = await axiosInstance.get(ApiUrl, {
    params: {
      graph_type,
      "filters[major]": major,
      "filters[year]": year,
      "filters[nationality]": nationality,
      "filters[degree]": degree,
      ...(employment_type?.length
        ? { "filters[employment_type]": employment_type }
        : {}),
      "filters[country]": country,
    },
    headers: headers_with_token()?.headers,
  });
  return response?.data;
};

export const getFiltersData = async ({ dataSchool = null }) => {
  const ApiUrl =
    dataSchool === "all"
      ? `opts/filter_options?all_schools=true`
      : `opts/filter_options?school_id=${dataSchool}`;
  const response = await axiosInstance.get(ApiUrl, headers_with_token());
  return response?.data;
};

export const getGraphTableData = async ({
  dataSchool = null,
  page = 1,
  per_page = 10,
  search = null,
  major = null,
  year = null,
  nationality = null,
  degree = null,
  employment_type = null,
  country = null,
  order_by = null,
  order_direction = null,
}) => {
  const ApiUrl =
    dataSchool === "all"
      ? `opts?all_schools=true`
      : `opts?school_id=${dataSchool}`;
  const response = await axiosInstance.get(ApiUrl, {
    params: {
      search: search?.length > 0 ? search : null,
      page,
      per_page,
      "filters[major]": major,
      "filters[year]": year,
      "filters[nationality]": nationality,
      "filters[degree]": degree,
      "filters[employment_type]": employment_type,
      "filters[country]": country,
      order_by,
      order_direction,
    },
    headers: headers_with_token()?.headers,
  });
  return response?.data;
};
