import { useEffect } from "react";
import ActionCable from "actioncable";
import { useDispatch } from "react-redux";

import { useAppState } from "./context";
import { config } from "./constant/config";
import {
  allNotificationsCountAPI,
  notificationsListAPI,
} from "./services/notificationServices";
import {
  setConversationChannel,
  setConversationReactionsChannel,
} from "./redux/reducers/webSocketReducer";
import {
  setAllNotifications,
  setLiveNotificationsChat,
  setLiveNotificationsEmployerJobs,
  setLiveNotificationsFeaturedJobs,
  setLiveNotificationsTopic,
} from "./redux/reducers/notificationReducer";

const BASE_SOCK_URL = config().SOCKET_URL;

/**
 * This component should be called at root level of app and provides socket connection to app.
 * This component allows custom logic to be implemented based on socket channel broadcast.
 * This component has access to themes, useAppState, and usePersist state.
 * This component is called when ever user is logged in, only once, to avoid multiple socket connections.
 * @param {boolean} loggedIn User Login status
 * @returns null
 */
export default function WebsocketProvider({ loggedIn = false }) {
  const { setNotifications } = useAppState("notifications");
  const {
    setSocketConnectionStatus,
    setSocketReactionsConnectionStatus,
    setConversationChannelBroadcast,
    setConversationReactionsChannelBroadcast,
  } = useAppState("chat");

  const dispatch = useDispatch();

  useEffect(() => {
    const loginUserId = localStorage.getItem("userId");
    if (loggedIn === false || loginUserId === null) {
      console.error("Websokcet Provider: User not logged in or does not exist");
      return;
    }
    console.log("/// Websocket Provider Executed ///");

    // Call All Notification Count API
    (async () => {
      try {
        const response = await allNotificationsCountAPI();
        if (response?.success) {
          // Update Persist State
          dispatch(setAllNotifications(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    })();

    // Create websocket connection
    const cable = ActionCable.createConsumer(`${BASE_SOCK_URL}${loginUserId}`);

    // Notification channel subscription
    cable.subscriptions.create(
      {
        channel: "NotificationChannel",
      },
      {
        connected: () => {
          console.log("MSG Socket Notification Channel: Status Connected");
        },
        received: (message) => {
          console.log("MSG Socket Notification Channel - Broadcast: ", message);

          // Update Persist storage
          if (message) {
            if (message.notification_type === "topic")
              dispatch(setLiveNotificationsTopic(message));
            else if (message.notification_type === "chat")
              dispatch(setLiveNotificationsChat(message));
            else if (message.notification_type === "employer_job")
              dispatch(setLiveNotificationsEmployerJobs(message));
            else if (message.notification_type === "featured_job")
              dispatch(setLiveNotificationsFeaturedJobs(message));
          }

          // Call notification list API and update app state
          (async () => {
            try {
              const response = await notificationsListAPI();
              if (response?.success) {
                setNotifications(response.data);
              }
            } catch (error) {
              console.error(error);
            }
          })();
        },
        disconnected: () => {
          console.log("MSG Socket Notification Channel: Status Disconnected");
        },
      }
    );

    // Chat channel subscription
    const conversationChannel = cable.subscriptions.create(
      {
        channel: "ConversationChannel",
      },
      {
        connected: () => {
          setSocketConnectionStatus(true);
          console.log("MSG Socket Conversation Channel: Status Connected");
        },
        received: (data) => {
          setConversationChannelBroadcast(data);
          console.log("MSG Socket Conversation Channel - Broadcast: ", data);
        },
        create: function (chatContent) {
          this.perform("speak", {
            content: chatContent,
          });
          console.log(
            "MSG Socket Conversation Channel - Speak Payload: ",
            chatContent
          );
        },
        disconnected: () => {
          setSocketConnectionStatus(false);
          console.log("MSG Socket Conversation Channel: Status Disconnected");
        },
      }
    );

    // Chat Reactions channel subscription
    const conversationReactionsChannel = cable.subscriptions.create(
      {
        channel: "ReactionChannel",
      },
      {
        connected: () => {
          setSocketReactionsConnectionStatus(true);
          console.log(
            "MSG Socket Conversation Reactions Channel: Status Connected"
          );
        },
        received: (data) => {
          setConversationReactionsChannelBroadcast(data);
          console.log(
            "MSG Socket Conversation Reactions Channel - Broadcast: ",
            data
          );
        },
        message: function (reactionContent) {
          this.perform("receive", reactionContent);
          console.log(
            "MSG Socket Conversation Reactions Channel - Message Payload: ",
            reactionContent
          );
        },
        disconnected: () => {
          setSocketReactionsConnectionStatus(false);
          console.log(
            "MSG Socket Conversation Reactions Channel: Status Disconnected"
          );
        },
      }
    );

    // Update redux store with conversation channel reference
    dispatch(setConversationChannel(conversationChannel || null));

    // Update redux store with conversation reactions channel reference
    dispatch(
      setConversationReactionsChannel(conversationReactionsChannel || null)
    );

    // Disconnects the socket reference when the connection is closed
    return () => {
      cable?.disconnect && cable.disconnect();
      setSocketConnectionStatus(false);
      setSocketReactionsConnectionStatus(false);
      console.log("/// Websocket Disconnected ///");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return null;
}
