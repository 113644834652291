import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AccordionViewStyles from "./AccordionViewStyles";

const AccordionView = ({
  children,
  header = "",
  infoIconTooltip,
  label = "",
}) => {
  return (
    <AccordionViewStyles>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${label}-content`}
          id={`${label}-header`}
        >
          <span className="accordion-view__title">{header}</span>
          {infoIconTooltip ? infoIconTooltip : <></>}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </AccordionViewStyles>
  );
};

export default AccordionView;
